<template>
    <div>
        <b-card>
          <div class="d-flex justify-content-end pb-1">
            <b-button size="sm" variant="relief-success" v-b-modal.add-modal><feather-icon icon="PlusIcon" /> เพิ่มอุปกรณ์</b-button>
          </div>
          <list-table />
        </b-card>
        <add-modal />
    </div>
</template>

<script>
import { BCard, BCardText, BButton } from 'bootstrap-vue'
import ListTable from './component/ListTable.vue'
import AddModal from './component/AddModal.vue'


export default {
  components: {
    BCard,
    BCardText,
    BButton,
    ListTable,
    AddModal
  },
}
</script>

<style>

</style>
